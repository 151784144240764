import React from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import Carousel, { Modal, ModalGateway } from 'react-images'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './carousel.css'

const CarouselContainer = styled.div`
  padding-bottom: 40px;
  overflow: hidden;
  max-width: 100vw;

  .slick-list {
    padding: 0 !important;
  }

  .slick-slide {
  }

  .slick-slide img {
    ${ props => props.theme.media.phone`
      border-radius: 0;
      margin: 0;
      width: 100vw !important;
      height: auto !important;
      max-height: none;
    ` }
  }

  .slick-active {
  }

  .slick-center,
  .slick-current {
  }

  .slick-track {
    display: flex;
    align-items: center;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  align-contents: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  overflow: hidden;

  &:focus {
    outline: none;
  }
`

const ProductImage = styled.img`
  margin: 0 auto;
  max-height: 400px;
  width: auto;

  border-radius: 5px;
  background-color: #fafafa;
  border: 0;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.05);
`

export class ImageCarousel extends React.Component {
  state = {
    clientXonMouseDown: null,
    clientYonMouseDown: null,
    modalIsOpen: false,
    index: 0
  }

  toggleModal = index => {
    this.setState(state => ({
      modalIsOpen: !state.modalIsOpen,
      index
    }))
    this.updateCarousel(index)
  }

  handleOnMouseDown = e => {
    this.setState({
      clientXonMouseDown: e.clientX,
      clientYonMouseDown: e.clientY
    })
    e.preventDefault()
  }

  handleOnClick = (e, index) => {
    e.stopPropagation()
    if (this.state.clientXonMouseDown !== e.clientX ||
        this.state.clientYonMouseDown !== e.clientY) {
      e.preventDefault()
    } else {
      // this.toggleModal(index)
    }
  }

  updateCarousel = index => {
    if (this.carousel) {
      this.carousel.slickGoTo(index, true)
    }
  }

  render () {
    const { modalIsOpen } = this.state
    const { images } = this.props
      /*
    const slidesToShow = images.length > 3
      ? 3
      : images.length
      */
    const slidesToShow = 1
    const initalSlide = images.length >= 3
      ? 2
      : 0
    const settings = {
      dots: true,
      speed: 500,
      slidesToShow,
      initalSlide,
      infinite: true,
      arrows: true,
      responsive: [{
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }],
      afterChange: index => {
        this.setState({
          index
        })
      }
    }

    return (
      <CarouselContainer>
        <Slider {...settings} ref={c => (this.carousel = c)}>
          {images && images.map((i, index) => (
            <ImageWrapper key={i.image.src}>
              <ProductImage
                onMouseDown={this.handleOnMouseDown}
                onClick={e => {
                  this.handleOnClick(e, index)
                }}
                src={i.thumb.src} />
            </ImageWrapper>
          ))}
        </Slider>
        <ModalGateway>
          {modalIsOpen
            ? (
              <Modal onClose={e => this.toggleModal(this.state.index)}>
                <Carousel
                  trackProps={{
                    onViewChange: this.updateCarousel
                  }}
                  currentIndex={this.state.index}
                  views={images.map(i => i.image)} />
              </Modal>)
            : null
          }
        </ModalGateway>
      </CarouselContainer>
    )
  }
}

export class ItemCarousel extends React.Component {
  state = {
    clientXonMouseDown: null,
    clientYonMouseDown: null,
    modalIsOpen: false,
    index: 0
  }

  toggleModal = index => {
    this.setState(state => ({
      modalIsOpen: !state.modalIsOpen,
      index
    }))
    this.updateCarousel(index)
  }

  handleOnMouseDown = e => {
    this.setState({
      clientXonMouseDown: e.clientX,
      clientYonMouseDown: e.clientY
    })
    e.preventDefault()
  }

  handleOnClick = (e, index) => {
    e.stopPropagation()
    if (this.state.clientXonMouseDown !== e.clientX ||
        this.state.clientYonMouseDown !== e.clientY) {
      e.preventDefault()
    } else {
      this.toggleModal(index)
    }
  }

  updateCarousel = index => {
    if (this.carousel) {
      this.carousel.slickGoTo(index, true)
    }
  }

  render () {
    const { gallery } = this.props
    const settings = {
      dots: true,
      speed: 500,
      slidesToShow: gallery.length < 4
        ? gallery.length
        : 4,
      infinite: true,
      arrows: true,
      responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: gallery.length < 3
            ? gallery.length
            : 3
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: gallery.length < 2
            ? gallery.length
            : 2,
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: gallery.length < 2
            ? gallery.length
            : 2,
        }
      }],
      afterChange: index => {
        this.setState({
          index
        })
      }
    }

    return (
      <CarouselContainer>
        <Slider {...settings} ref={c => (this.carousel = c)}>
          {gallery && gallery.map((item, index) => {
            return (
              <ImageWrapper key={index}>
                { item }
              </ImageWrapper>
            )
          })}
        </Slider>
      </CarouselContainer>
    )
  }
}
