import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import { ImageCarousel } from './carousel'
import { ContentWrapper } from '../components/containers'
import { Ribbon } from './ribbon'
import { Seo } from '../components/seo'
import {
  formatAffiliateLink,
  formatBuyText,
  updateReviewDom,
  INSTAGRAM_LINK
} from '../utils'
import { ComingSoon } from './comingSoon'
import Img from 'gatsby-image'

export const ItemGrid = styled.div`
  display: flex;
  flex-flow: wrap;
  padding: 10px 0 0 0;
  justify-content: flex-start;

  ${ props => props.spaceBetween && css`
    justify-content: space-between;
  ` }
  ${ props => props.justifyCenter && css`
    justify-content: center;
  ` }
`

export const ItemWrapper = styled.div`
  position: relative;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  opacity: 1;
  transition: all 0.25s;

  width: ${ props => props.desktopWidth || '33%' };
  height: ${ props => props.desktopHeight || '500px' };

  ${ props => props.wide && `
   width: 50%;
   height: 300px;
  ` }

  ${ props => props.theme.media.phone`
    max-width: 100%;
    width: 100%;
    height: 500px;

    ${ props => props.wide && `
      width: 100%;
      height: 200px;
    ` })
  ` }

  &:hover {
    opacity: 0.75;
    transition: all 0.25s;
  }
`

export const ItemWrapperOld = styled.div`
  position: relative;
  margin: 10px;
  border-radius: 2px;
  background-color: #fafafa;
  border: 0;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  display: flex;
  align-items: center;

  width: ${ props => props.width / 2 }px;
  min-height: ${ props => props.height / 2 }px;

  ${ props => props.wide && `
   width: 400px;
   height: 300px;
  ` }

  ${ props => props.theme.media.phone`
    max-width: 100%;
    width: ${ props => props.width / (props.mobileDivisor || 4) }px;
    min-height: ${ props => props.height / (props.mobileDivisor || 4) }px;

    ${ props => props.wide && `
      width: ${ 400 / 1.5 }px;
      height: ${ 300 / 1.5 }px;
    ` })
  ` }

  &:hover {
    opacity: 0.75;
  }
`

const ItemLinkOld = styled(props => <Link {...props} />)`
  display: block;
`

const ItemLink = styled(props => <Link {...props} />)`
  display: flex;
  height: 100%;
  width: 100%;
`

const ItemGatsbyImage = styled(props => <Img {...props} />)`
  border-radius: 2px;
  background-color: #fafafa;
  border: 0;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  margin: 4px 2px;
  width: 100%;
  height: 100%;
`

export const ItemImageOld = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  display: block;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${ props => props.src });
`

export const ItemTitle = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  margin: 0 5%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    background-color: rgba(255, 255, 255, 0.8);
    min-height: 100px;
    width: 100%;
    letter-spacing: 2px;
    text-align: center;
    font-size: 1.5rem;
    text-decoration: none;
    font-family: ${ props => props.theme.bodyFont };
    color: ${ props => props.theme.black };
    text-transform: uppercase;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const ReviewText = styled.div`
  font-family: ${ props => props.theme.headerFont };
  font-size: 1rem;
  line-height: 1.8rem;
  font-family: ${ props => props.theme.bodyFont };
  font-weight: ${ props => props.theme.bodyWeight };
  padding: 0 20px;

  a {
    color: ${ props => props.theme.black };
  }

  strong {
    font-weight: 600;
  }

  img {
    max-width: 100%;
    width: 600px;
    height: auto;
    display: block;
    margin: 0 auto;
  }
`

const renderItemTitle = props => {
  if (props.title) {
    return (<ItemTitle><div>{ props.title }</div></ItemTitle>)
  } else {
    return null
  }
}

export const Item = props => {
  if (!props.image) {
    return null
  }
  if (props.image.fluid) {
    return (
      <ItemWrapper
        wide={props.image.fluid.aspectRatio > 1}
        desktopHeight={props.desktopHeight}
        desktopWidth={props.desktopWidth}>
        { props.comingSoon === true && (
          <Ribbon><span>Coming Soon</span></Ribbon>
        )}
        <ItemLink to={props.url}>
          <VisibilitySensor>
            <ItemGatsbyImage
              loading="eager"
              fluid={props.image.fluid}
              title={props.image.title}
            />
          </VisibilitySensor>
          { renderItemTitle(props) }
        </ItemLink>
      </ItemWrapper>
    )
  } else {
    return (
      <ItemWrapperOld
        mobileDivisor={ props.mobileDivisor }
        wide={props.image.height < props.image.width}
        height={props.image.height}
        width={props.image.width}>
        { props.comingSoon === true && (
          <Ribbon><span>Coming Soon</span></Ribbon>
        )}
        <ItemLinkOld to={props.url}>
          <VisibilitySensor>
            <ItemImageOld src={props.image.src} alt={props.imageTitle} />
          </VisibilitySensor>
          { renderItemTitle(props) }
        </ItemLinkOld>
      </ItemWrapperOld>
    )
  }
}

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const BuyButton = styled.a`
  border-radius: 3px;
  padding: 0.5rem 2rem;
  margin: 0.2rem 1rem;
  width: 11rem;
  background: transparent;
  color: white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: 1px 1px 1px 3px rgba(0, 0, 0, 0.05);
  font-family: ${ props => props.theme.bodyFont };
  transition: opacity 0.25s;

  &:hover {
    color: white;
    transform: scale(1.02);
    transition: all 0.25s;
  }
`

export const BuyThis = styled(props => <BuyButton {...props} />)`
  border: 1px solid ${ props => props.theme.black };
  background-color: ${ props => props.theme.black };
`

const FancyBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const FancyBox1 = styled(props => <FancyBox {...props} />)`
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  padding: 30px 20px;
  background-color: ${ props => props.theme.navColor };
  width: 500px;

  ${ props => props.theme.media.phone`
    width: auto;
    margin: 20px -10px 20px -10px;
  ` }
`

const titleStyle = `
  text-align: center;
  margin: 0;
  padding: 0 20px;
  font-size: 1.5rem;
  line-height: 2rem;
  text-transform: uppercase;
  letter-spacing: 6px;
`

const FancyTitle2 = styled.h2`
  ${ titleStyle }
  font-size: 1.2rem;
  font-family: ${ props => props.theme.headerFont };
`

const FancyTitle1 = styled.h1`
  ${ titleStyle }
  font-size: 2.5rem;
  font-weight: 500;
  font-family: ${ props => props.theme.headerFont };
  padding: 20px;
  line-height: 3.2rem;

  ${ props => props.theme.media.phone`
    font-size: 1.5rem;
  ` }
`

const FancyCategories = styled.div`
  ${ titleStyle }
  font-size: 1.2rem;
  font-family: ${ props => props.theme.bodyFont };
`

const FancyLine = styled.div`
  border-top: ${ props => props.theme.black } 1px solid;
  flex: 1 1 auto;
  line-height: 2rem;
  margin-top: 1rem;
`

export const FancyHeader2 = props => (
  <FancyBox style={{ 'padding': '20px 0 0 0' }}>
    <FancyLine />
    <FancyTitle2>{ props.children }</FancyTitle2>
    <FancyLine />
  </FancyBox>
)

export const FancyHeader1 = ({ children, categories, subtitle }) => (
  <FancyBox1>
    <FancyTitle1>
      { children }
    </FancyTitle1>
    { subtitle && (
      <FancyTitle2>
        { subtitle }
      </FancyTitle2>
    )}
    { categories && (
      <FancyCategories>
        { categories.map(c => {
          return (<><span>{c}</span> </>)
        }) }
      </FancyCategories>
    ) }
  </FancyBox1>
)

const ProductGrid = styled.div`
  display: flex;
  flex-direction: column;

  ${ props => props.theme.media.desktop`
    flex-direction: column;
    width: 100%;
  ` }
`

const ProductColumns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;

  ${ props => props.theme.media.phone`
    flex-direction: column;
    width: 100%;
  ` }
`

const InstaLink = props => (
  <a style={{ display: 'inline' }} href={ INSTAGRAM_LINK }>
    { props.children }
  </a>
)

export class DetailTemplate extends React.Component {
  componentDidMount () {
    window.setTimeout(() => {
      updateReviewDom()
    }, 500)
  }

  renderReview () {
    if (!this.props.review) {
      return null
    }
    return (
      <>
        {/*
        <FancyHeader2>Review</FancyHeader2>
        */}
        <ReviewText
          id="review"
          dangerouslySetInnerHTML={{
            __html: this.props.review.childMarkdownRemark.html,
          }}
        />
      </>
    )
  }

  render () {
    const gallery = (this.props.gallery || [])
    if (this.props.comingSoon === true) {
      const description = `
        ${ this.props.title } is not available yet.
        Don't fret.  Just follow me Instagram to
        be the first to see when this becomes available.
      `
      return (
        <ContentWrapper>
          <Seo
            title={this.props.title}
            description={description}
            location={this.props.location} />
          <FancyHeader1>Coming soon!</FancyHeader1>
          <ProductColumns>
            <ReviewText>
              <strong>{ this.props.title }</strong> is not available yet.
              Don't fret.
              Just follow me <InstaLink>Instagram</InstaLink> to
              be the first to see when this becomes available.
            </ReviewText>
            <ComingSoon />
          </ProductColumns>
        </ContentWrapper>
      )
    } else {
      const description = this.props.review
        ? this.props.review.review
        : null
      return (
        <ContentWrapper>
          <Seo
            title={this.props.title}
            description={description}
            location={this.props.location} />
          <FancyHeader1 subtitle={this.props.subtitle}>
            {this.props.title}
          </FancyHeader1>
          <ProductGrid>
            <ImageCarousel images={gallery} />
            { this.props.productLink && (
              <ButtonRow>
                <BuyThis href={formatAffiliateLink(this.props.productLink)}
                  target="_blank"
                  rel="noopener">{ formatBuyText(this.props.productLink) }
                </BuyThis>
              </ButtonRow>
            )}
            { this.renderReview() }
          </ProductGrid>
          { this.props.children }
        </ContentWrapper>
      )
    }
  }
}
