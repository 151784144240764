import styled from 'styled-components'

export const Ribbon = styled.div`
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 300;
  overflow: hidden;
  width: 200px;
  height: 200px;
  text-align: right;
  pointer-events: none;

  span {
    font-size: 0.6rem;
    letter-spacing: 2px;
    font-weight: bold;
    color: ${ props => props.theme.black };
    text-transform: uppercase;
    text-align: center;
    line-height: 1.5rem;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 200px;
    display: block;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 30px; left: -60px;
  }

  span::before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid rgba(255, 255, 255, 0.95);
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid rgba(255, 255, 255, 0.95);
  }
  span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid rgba(255, 255, 255, 0.95);
    border-bottom: 3px solid transparent;
    border-top: 3px solid rgba(255, 255, 255, 0.95);
  }
`
